import React, { useState, useEffect, useCallback } from 'react';

import { Container, Checkbox, Button, Grid, Snackbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MUIDataTable from 'mui-datatables';

import UserDialog from '../../components/UserDialog';
import api from '../../services/api';
import useStyles from './styles';

interface User {
  id: string;
  name: string;
  role: string;
  email: string;
  password: string;
}

interface SnackBar {
  open: boolean;
  description: string;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Users: React.FC = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectUser, setSelectUser] = useState<User>({} as User);

  const [snackBarError, setSnackBarError] = useState<SnackBar>({
    open: false,
    description: '',
  });

  const [snackBarSuccess, setSnackBarSuccess] = useState<SnackBar>({
    open: false,
    description: '',
  });

  useEffect(() => {
    api.get('/users/').then((response) => {
      setUsers(response.data);
    });
  }, []);

  const handleOpenNew = () => {
    setSelectUser({} as User);
    setOpen(true);
  };

  const handleOpenEdit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleErrorUpdateUser = useCallback(() => {
    setSnackBarError({
      open: true,
      description: 'Ocorreu um erro ao tentar salvar usuário',
    });
  }, []);

  const handleSuccessUpdateUser = useCallback(() => {
    api.get('/users/').then((response) => {
      setUsers(response.data);
    });

    setSnackBarSuccess({
      open: true,
      description: 'Usuário salvo com sucesso!',
    });
  }, []);

  const handleCloseSnackBarError = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarError({ open: false, description: '' });
    },
    [],
  );

  const handleCloseSnackBarSuccess = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarSuccess({ open: false, description: '' });
    },
    [],
  );

  return (
    <>
      <Snackbar
        open={snackBarError.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarError} severity="error">
          {snackBarError.description}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackBarSuccess.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarSuccess} severity="success">
          {snackBarSuccess.description}
        </Alert>
      </Snackbar>

      <div className={classes.root}>
        <UserDialog
          open={open}
          onClose={handleClose}
          selectedUser={selectUser}
          onError={handleErrorUpdateUser}
          onSuccess={handleSuccessUpdateUser}
        />
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenNew}
              >
                Novo usuário
              </Button>
            </Grid>
            <div className={classes.containerList}>
              <MUIDataTable
                title="Lista de usuários"
                data={users}
                columns={[
                  {
                    name: 'Edit',
                    label: 'Editar',
                    options: {
                      filter: false,
                      sort: false,
                      empty: true,
                      print: false,
                      download: false,
                      customBodyRenderLite: (dataIndex, rowIndex) => {
                        return (
                          <div>
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                setSelectUser(users[rowIndex]);
                                handleOpenEdit();
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        );
                      },
                    },
                  },
                  { name: 'id', options: { display: 'excluded' } },
                  {
                    name: 'name',
                    label: 'Nome completo',
                    options: {
                      customBodyRender: (value) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              maxWidth: 250,
                              height: 58,
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {value}
                            </span>
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'email',
                    label: 'E-mail',
                    options: {
                      customBodyRender: (value) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              maxWidth: 250,
                              height: 58,
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {value}
                            </span>
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'role',
                    label: 'Administrador',
                    options: {
                      customBodyRender: (value) => {
                        return (
                          <Checkbox checked={value === 'admin'} disabled />
                        );
                      },
                    },
                  },
                ]}
                options={{
                  pagination: false,
                  responsive: 'standard',
                  selectableRows: 'none',
                  textLabels: {
                    body: {
                      noMatch: 'Nenhum registro encontrado',
                      toolTip: 'Classificar',
                    },
                    pagination: {
                      next: 'Próxima página',
                      previous: 'Página anterior',
                      rowsPerPage: 'Por página:',
                      displayRows: 'de',
                    },
                    toolbar: {
                      search: 'Busca',
                      downloadCsv: 'Download CSV',
                      print: 'Imprimir',
                      viewColumns: 'Ver Colunas',
                      filterTable: 'Filtrar Tabelas',
                    },
                    filter: {
                      all: 'Todos',
                      title: 'FILTROS',
                      reset: 'LIMPAR',
                    },
                    viewColumns: {
                      title: 'Ver Colunas',
                      titleAria: 'Ver/Esconder Colunas da Tabela',
                    },
                    selectedRows: {
                      text: 'registros(s) selecionados',
                      delete: 'Excluir',
                      deleteAria: 'Excluir registros selecionados',
                    },
                  },
                }}
              />
            </div>
          </Container>
        </main>
      </div>
    </>
  );
};

export default Users;
