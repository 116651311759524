import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core';

import AppProvider from './hooks';
import Routes from './routes';
import globalTheme from './styles/global';

const App: React.FC = () => (
  <>
    <MuiThemeProvider theme={globalTheme}>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>
      </BrowserRouter>
    </MuiThemeProvider>
  </>
);

export default App;
