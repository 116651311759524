import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { DropContainer, UploadMessage } from './styles';

interface UploadProps {
  title: string;
  onUpload: (files: File[]) => void;
}

const Upload: React.FC<UploadProps> = ({ title, onUpload }) => {
  const onDrop = useCallback(
    (files: File[]) => {
      onUpload(files);
    },
    [onUpload],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: [
      '.jar',
      'application/java-archive',
      'application/x-java-archive',
      'application/x-jar',
    ],
    multiple: false,
    onDrop,
  });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return <UploadMessage>{title}</UploadMessage>;
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          Tipo de arquivo não suportado
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte as imagens aqui</UploadMessage>;
  }, [isDragActive, isDragReject, title]);

  return (
    <DropContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {renderDragMessage()}
    </DropContainer>
  );
};

export default Upload;
