import React, { useState, useEffect, useCallback } from 'react';

import { Checkbox, Snackbar, Container } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { format, parseISO } from 'date-fns';
import MUIDataTable from 'mui-datatables';

import CustomerDialog from '../../components/CustomerDialog';
import api from '../../services/api';
import useStyles from './styles';

interface Customer {
  id: string;
  company_name: string;
  trade_name: string;
  cnpj: string;
  address: string;
  number: string;
  neighborhood: string;
  complement: string;
  cep: string;
  city: string;
  state: string;
  contact: string;
  phone: string;
  cell_phone: string;
  email: string;
  message: string;
  observation: string;
  contract_date: Date;
  blocked: boolean;
}

interface SnackBar {
  open: boolean;
  description: string;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Customer: React.FC = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectCustomer, setSelectCustomer] = useState<Customer>(
    {} as Customer,
  );

  const [snackBarError, setSnackBarError] = useState<SnackBar>({
    open: false,
    description: '',
  });

  const [snackBarSuccess, setSnackBarSuccess] = useState<SnackBar>({
    open: false,
    description: '',
  });

  useEffect(() => {
    api.get('/customers/').then((response) => {
      setCustomers(response.data);
    });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackBarError = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarError({ open: false, description: '' });
    },
    [],
  );

  const handleCloseSnackBarSuccess = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarSuccess({ open: false, description: '' });
    },
    [],
  );

  const handleErrorUpdateCustomer = useCallback(() => {
    setSnackBarError({
      open: true,
      description: 'Ocorreu um erro ao tentar salvar alterações do cliente',
    });
  }, []);

  const handleSuccessUpdateCustomer = useCallback(() => {
    api.get('/customers/').then((response) => {
      setCustomers(response.data);
    });

    setSnackBarSuccess({
      open: true,
      description: 'Alterações do cliente salvas com sucesso!',
    });
  }, []);

  return (
    <>
      <Snackbar
        open={snackBarError.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarError} severity="error">
          {snackBarError.description}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackBarSuccess.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarSuccess} severity="success">
          {snackBarSuccess.description}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <CustomerDialog
          open={open}
          onClose={handleClose}
          selectedCustomer={selectCustomer}
          onError={handleErrorUpdateCustomer}
          onSuccess={handleSuccessUpdateCustomer}
        />
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            <MUIDataTable
              title="Lista de clientes"
              data={customers}
              columns={[
                {
                  name: 'Edit',
                  label: 'Editar',
                  options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    print: false,
                    download: false,
                    customBodyRenderLite: (dataIndex, rowIndex) => {
                      return (
                        <div>
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              handleOpen();
                              setSelectCustomer(customers[rowIndex]);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                      );
                    },
                  },
                },
                { name: 'id', options: { display: 'excluded' } },
                {
                  name: 'company_name',
                  label: 'Empresa',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'trade_name',
                  label: 'Nome comercial',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            width: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'cnpj',
                  label: 'CNPJ',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 115,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'address',
                  label: 'Endereço',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'number',
                  label: 'Numero',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 70,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'neighborhood',
                  label: 'Bairro',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'complement',
                  label: 'Complemento',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'cep',
                  label: 'CEP',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'city',
                  label: 'Cidade',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'state',
                  label: 'Estado',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'contact',
                  label: 'Contato',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'phone',
                  label: 'Telefone',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'cell_phone',
                  label: 'Celular',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'email',
                  label: 'E-mail',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'message',
                  label: 'Mensagem',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            width: 300,
                            height: 58,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {value}
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'observation',
                  label: 'Observação',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            width: 300,
                            height: 58,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {value}
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'contract_date',
                  label: 'Data de contrato',
                  options: {
                    customBodyRender: (value) => {
                      const date = parseISO(value);
                      const formattedDate = format(date, 'dd/MM/yyyy');
                      return (
                        <div
                          style={{
                            display: 'flex',
                            width: 110,
                            height: 58,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {formattedDate}
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'blocked',
                  label: 'Bloqueado',
                  options: {
                    customBodyRender: (value) => {
                      return <Checkbox checked={value} disabled />;
                    },
                  },
                },
              ]}
              options={{
                pagination: false,
                responsive: 'standard',
                selectableRows: 'none',
                textLabels: {
                  body: {
                    noMatch: 'Nenhum registro encontrado',
                    toolTip: 'Classificar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Por página:',
                    displayRows: 'de',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Download CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabelas',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas da Tabela',
                  },
                  selectedRows: {
                    text: 'registros(s) selecionados',
                    delete: 'Excluir',
                    deleteAria: 'Excluir registros selecionados',
                  },
                },
              }}
            />
          </Container>
        </main>
      </div>
    </>
  );
};

export default Customer;
