import React, { useState, useEffect, useCallback } from 'react';

import {
  Container,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Grid,
  Snackbar,
  Button,
  ListItemIcon,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import ConfirmDialog from '../../components/ConfirmDialog';
import ExecutableDialog from '../../components/ExecutableDialog';
import api from '../../services/api';
import useStyles from './styles';

interface Executable {
  id: string;
  version: number;
  version_title: string;
  production: boolean;
}

interface SnackBar {
  open: boolean;
  description: string;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Executable: React.FC = () => {
  const [openExecutableDialog, setOpenExecutableDialog] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [executables, setExecutables] = useState<Executable[]>([]);
  const [editExecutable, setEditExecutable] = useState<Executable>(
    {} as Executable,
  );
  const [deleteExecutable, setDeleteExecutable] = useState<Executable>(
    {} as Executable,
  );

  const [snackBarError, setSnackBarError] = useState<SnackBar>({
    open: false,
    description: '',
  });

  const [snackBarSuccess, setSnackBarSuccess] = useState<SnackBar>({
    open: false,
    description: '',
  });

  const classes = useStyles();

  useEffect(() => {
    api.get('/executables/').then((response) => {
      setExecutables(response.data);
    });
  }, []);

  const handleOpen = useCallback(() => {
    setEditExecutable({} as Executable);
    setOpenExecutableDialog(true);
  }, []);

  const handleEdit = useCallback((executable: Executable) => {
    setEditExecutable(executable);
    setOpenExecutableDialog(true);
  }, []);

  const handleDelete = useCallback((executable: Executable) => {
    setDeleteExecutable(executable);
    setOpenConfirmDialog(true);
  }, []);

  const handleClose = useCallback(() => {
    api.get('/executables/').then((response) => {
      setExecutables(response.data);
    });
    setOpenExecutableDialog(false);
  }, []);

  const handleCloseConfirmDialog = useCallback(() => {
    setDeleteExecutable({} as Executable);
    setOpenConfirmDialog(false);
  }, []);

  const handleUploadError = useCallback(() => {
    setSnackBarError({
      open: true,
      description: 'Ocorreu um erro ao salvar o executável de sistema',
    });
  }, []);

  const handleUpdateSuccess = useCallback(() => {
    setSnackBarSuccess({
      open: true,
      description: 'Executável do sistema salvo com sucesso!',
    });
  }, []);

  const handleConfirmDialog = useCallback(async () => {
    try {
      await api.delete(`/executables/${deleteExecutable.id}`);

      const response = await api.get('/executables/');

      setExecutables(response.data);

      setSnackBarSuccess({
        open: true,
        description: 'Executável do sistema deletado com sucesso!',
      });
    } catch {
      setSnackBarError({
        open: true,
        description: 'Ocorreu um erro ao deletar o executável do sistema',
      });
    } finally {
      setOpenConfirmDialog(false);
    }
  }, [deleteExecutable.id]);

  const handleCloseSnackBarError = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarError({ open: false, description: '' });
    },
    [],
  );

  const handleCloseSnackBarSuccess = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarSuccess({ open: false, description: '' });
    },
    [],
  );

  return (
    <>
      <Snackbar
        open={snackBarError.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarError} severity="error">
          {snackBarError.description}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackBarSuccess.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarSuccess} severity="success">
          {snackBarSuccess.description}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <ExecutableDialog
          open={openExecutableDialog}
          onClose={handleClose}
          selectedExecutable={editExecutable}
          onError={handleUploadError}
          onUpdate={handleUpdateSuccess}
        />
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          onConfirm={handleConfirmDialog}
          title="Excluir executável do sistema"
          description={`Deseja realmente excluir o executável do sistema versão ${deleteExecutable.version}?`}
        />
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Novo executável
              </Button>
            </Grid>
            <div className={classes.containerList}>
              {executables.map((executable) => (
                <List
                  key={executable.id}
                  component="nav"
                  aria-label="secondary mailbox folders"
                >
                  <ListItem>
                    <ListItemText
                      primary={`Versão do executável: ${executable.version_title}`}
                      secondary={`Número da versão: ${executable.version} - ${
                        executable.production
                          ? 'Em produção'
                          : 'Não está em produção'
                      }`}
                    />
                    <ListItemIcon
                      onClick={() => {
                        handleDelete(executable);
                      }}
                    >
                      <IconButton edge="end" aria-label="excluir">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemIcon
                      onClick={() => {
                        handleEdit(executable);
                      }}
                    >
                      <IconButton edge="end" aria-label="editar">
                        <EditIcon />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                  <Divider />
                </List>
              ))}
            </div>
          </Container>
        </main>
      </div>
    </>
  );
};

export default Executable;
