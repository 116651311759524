import React, { useState, useEffect, useCallback } from 'react';

import {
  Container,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Grid,
  Snackbar,
  Button,
  ListItemIcon,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import ConfirmDialog from '../../components/ConfirmDialog';
import ManagerDialog from '../../components/ManagerDialog';
import api from '../../services/api';
import useStyles from './styles';

interface Manager {
  id: string;
  version: number;
  version_title: string;
  production: boolean;
}

interface SnackBar {
  open: boolean;
  description: string;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Manager: React.FC = () => {
  const [openManagerDialog, setOpenManagerDialog] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [managers, setManagers] = useState<Manager[]>([]);
  const [editManager, setEditManager] = useState<Manager>({} as Manager);
  const [deleteManager, setDeleteManager] = useState<Manager>({} as Manager);

  const [snackBarError, setSnackBarError] = useState<SnackBar>({
    open: false,
    description: '',
  });

  const [snackBarSuccess, setSnackBarSuccess] = useState<SnackBar>({
    open: false,
    description: '',
  });

  const classes = useStyles();

  useEffect(() => {
    api.get('/managers/').then((response) => {
      setManagers(response.data);
    });
  }, []);

  const handleOpen = useCallback(() => {
    setEditManager({} as Manager);
    setOpenManagerDialog(true);
  }, []);

  const handleEdit = useCallback((manager: Manager) => {
    setEditManager(manager);
    setOpenManagerDialog(true);
  }, []);

  const handleDelete = useCallback((manager: Manager) => {
    setDeleteManager(manager);
    setOpenConfirmDialog(true);
  }, []);

  const handleClose = useCallback(() => {
    api.get('/managers/').then((response) => {
      setManagers(response.data);
    });
    setOpenManagerDialog(false);
  }, []);

  const handleCloseConfirmDialog = useCallback(() => {
    setDeleteManager({} as Manager);
    setOpenConfirmDialog(false);
  }, []);

  const handleUploadError = useCallback(() => {
    setSnackBarError({
      open: true,
      description: 'Ocorreu um erro ao salvar o gerenciador de sistema',
    });
  }, []);

  const handleUpdateSuccess = useCallback(() => {
    setSnackBarSuccess({
      open: true,
      description: 'Gerenciador do sistema salvo com sucesso!',
    });
  }, []);

  const handleConfirmDialog = useCallback(async () => {
    try {
      await api.delete(`/managers/${deleteManager.id}`);

      const response = await api.get('/managers/');

      setManagers(response.data);

      setSnackBarSuccess({
        open: true,
        description: 'Gerenciador do sistema deletado com sucesso!',
      });
    } catch {
      setSnackBarError({
        open: true,
        description: 'Ocorreu um erro ao deletar o gerenciador do sistema',
      });
    } finally {
      setOpenConfirmDialog(false);
    }
  }, [deleteManager.id]);

  const handleCloseSnackBarError = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarError({ open: false, description: '' });
    },
    [],
  );

  const handleCloseSnackBarSuccess = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarSuccess({ open: false, description: '' });
    },
    [],
  );

  return (
    <>
      <Snackbar
        open={snackBarError.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarError} severity="error">
          {snackBarError.description}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackBarSuccess.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarSuccess} severity="success">
          {snackBarSuccess.description}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <ManagerDialog
          open={openManagerDialog}
          onClose={handleClose}
          selectedManager={editManager}
          onError={handleUploadError}
          onUpdate={handleUpdateSuccess}
        />
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          onConfirm={handleConfirmDialog}
          title="Excluir gerenciador do sistema"
          description={`Deseja realmente excluir o gerenciador do sistema versão ${deleteManager.version}?`}
        />
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Novo gerenciador
              </Button>
            </Grid>
            <div className={classes.containerList}>
              {managers.map((manager) => (
                <List
                  key={manager.id}
                  component="nav"
                  aria-label="secondary mailbox folders"
                >
                  <ListItem>
                    <ListItemText
                      primary={`Versão do gerenciador: ${manager.version_title}`}
                      secondary={`Número da versão: ${manager.version} - ${
                        manager.production
                          ? 'Em produção'
                          : 'Não está em produção'
                      }`}
                    />
                    <ListItemIcon
                      onClick={() => {
                        handleDelete(manager);
                      }}
                    >
                      <IconButton edge="end" aria-label="excluir">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemIcon
                      onClick={() => {
                        handleEdit(manager);
                      }}
                    >
                      <IconButton edge="end" aria-label="editar">
                        <EditIcon />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                  <Divider />
                </List>
              ))}
            </div>
          </Container>
        </main>
      </div>
    </>
  );
};

export default Manager;
