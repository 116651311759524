import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import Sidebar from '../layout/Sidebar';
import useStyles from '../layout/stylesLayout';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  hideNavBar?: boolean;
  routeRole?: string;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  hideNavBar = false,
  routeRole = '',
  component: Component,
  ...rest
}) => {
  const classes = useStyles();
  const { user, checkExpiredToken } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        checkExpiredToken();

        if (isPrivate) {
          if (user) {
            // Caso a rota tenha uma regra específica e
            // o usuário não tiver a permissão para acessa-lá
            // então redireciona para o dashboard
            if (routeRole !== '' && routeRole !== user.role) {
              return (
                <Redirect
                  to={{
                    pathname: '/dashboard',
                    state: { from: location },
                  }}
                />
              );
            }

            if (hideNavBar) {
              return <Component />;
            }
            return (
              <>
                <div className={classes.root}>
                  <Sidebar />
                  <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Component />
                  </main>
                </div>
              </>
            );
          }

          // Se a rota é privada e não possuí token abre a interface de login
          return (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: location },
              }}
            />
          );
        }

        if (hideNavBar) {
          return <Component />;
        }
        return (
          <>
            <div className={classes.root}>
              <Sidebar />
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <Component />
              </main>
            </div>
          </>
        );
      }}
    />
  );
};

export default Route;
