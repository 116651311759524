import React, { useState, useEffect, useCallback } from 'react';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  WithStyles,
  withStyles,
  TextField,
  Typography,
  Box,
  Chip,
  Grid,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import * as Yup from 'yup';

import api from '../../services/api';
import Upload from '../Upload';
import { styles, useStyles } from './styles';

interface Manager {
  id: string;
  version_title: string;
  version: number;
  production: boolean;
}

interface ManagerDialogProps {
  open: boolean;
  selectedManager: Manager;
  onClose: () => void;
  onError: () => void;
  onUpdate: () => void;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  isUpload: boolean;
}

function CircularProgressWithLabel({
  value,
  ...props
}: CircularProgressProps & { value: number }) {
  const classes = useStyles();

  return (
    <Grid
      justify="space-between"
      alignItems="center"
      container
      className={classes.containerCircularProgress}
    >
      Fazendo upload do arquivo:
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} value={value} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, isUpload, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          disabled={isUpload}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ManagerDialog: React.FC<ManagerDialogProps> = ({
  open,
  selectedManager,
  onClose,
  onError,
  onUpdate,
}) => {
  const [manager, setManager] = useState(selectedManager);
  const [data, setData] = useState<FormData>({} as FormData);
  const [isDropData, setDropData] = useState(false);
  const [fileNameManager, setFileNameManager] = useState('');
  const [progressUpload, setProgressUpload] = useState(0);
  const [isSubmit, setSubmit] = useState(false);
  const [isEmptyData, setEmptyData] = useState(false);

  useEffect(() => {
    setManager(selectedManager);
  }, [selectedManager]);

  useEffect(() => {
    setData({} as FormData);
    setDropData(false);
    setFileNameManager('');
    setProgressUpload(0);
    setSubmit(false);
    setEmptyData(false);
  }, [open]);

  const onUpload = useCallback((files: File[]) => {
    files.forEach((file) => {
      const newData = new FormData();
      if (file) {
        newData.append('file', file, file.name);
      }

      setEmptyData(false);
      setData(newData);
      setDropData(true);
      setFileNameManager(file.name);
    });
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleError = () => {
    onError();
  };

  const handleUpdate = () => {
    onUpdate();
  };

  const handleDeleteManager = useCallback(() => {
    setData({} as FormData);
    setDropData(false);
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          isUpload={isSubmit}
        >
          Upload de um novo gerenciador do sistema
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={
              {
                id: manager.id,
                version: manager.version,
                version_title: manager.version_title,
                production: false,
              } as Manager
            }
            onSubmit={async (values: Manager, { setSubmitting }) => {
              try {
                if (!values.id) {
                  if (!data.values) {
                    setEmptyData(true);
                    setSubmitting(false);
                  } else {
                    setSubmitting(true);
                    setSubmit(true);

                    await api.post(
                      `/managers?version=${values.version}&version_title=${values.version_title}&production=${values.production}`,
                      data,
                      {
                        onUploadProgress: (progressEvent) => {
                          const progress: number = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                          );

                          setProgressUpload(progress);
                        },
                      },
                    );
                  }
                } else {
                  setSubmitting(true);
                  setSubmit(true);

                  await api.put(`/managers/${values.id}`, {
                    version: values.version,
                    version_title: values.version_title,
                    production: values.production,
                  });

                  handleUpdate();
                }
              } catch {
                handleError();
              } finally {
                handleClose();
              }
            }}
            validationSchema={Yup.object().shape({
              version: Yup.number()
                .required('Campo obrigatório')
                .min(1, 'O valor mínimo é um'),
              version_title: Yup.string().required('Campo obrigatório'),
              production: Yup.boolean().required('Campo obrigatório'),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  {selectedManager.id && (
                    <TextField
                      autoFocus
                      value={values.id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="dense"
                      autoComplete="off"
                      name="id"
                      label="ID"
                      type="text"
                      hidden
                      disabled
                      fullWidth
                      helperText={errors.id && touched.id && errors.id}
                    />
                  )}
                  <TextField
                    autoFocus
                    value={values.version_title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    margin="dense"
                    name="version_title"
                    label="Título da versão do gerenciador"
                    type="text"
                    disabled={isSubmitting}
                    fullWidth
                    helperText={
                      errors.version_title &&
                      touched.version_title &&
                      errors.version_title
                    }
                  />
                  <TextField
                    value={values.version}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    margin="dense"
                    name="version"
                    label="Versão do gerenciador"
                    type="number"
                    disabled={isSubmitting}
                    fullWidth
                    InputProps={{ inputProps: { min: 1 } }}
                    helperText={
                      errors.version && touched.version && errors.version
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.production}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          setFieldValue('production', event.target.checked);
                        }}
                        name="checkedProduction"
                        color="primary"
                        disabled={isSubmitting}
                      />
                    }
                    label="Está em produção?"
                  />
                  {!values.id && isEmptyData && (
                    <>
                      <Alert severity="error">
                        O gerenciador do sistema não foi selecionado!
                      </Alert>
                      <br />
                    </>
                  )}
                  {!values.id &&
                    (!isDropData ? (
                      <Upload
                        title="Arraste o gerenciador do sistema aqui ..."
                        onUpload={onUpload}
                      />
                    ) : (
                      <>
                        <br />
                        <Chip
                          label={`${fileNameManager}`}
                          onDelete={handleDeleteManager}
                          disabled={isSubmitting}
                        />
                      </>
                    ))}

                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      disabled={isSubmitting}
                      color="primary"
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      color="primary"
                    >
                      Salvar
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
          {!manager.id && isSubmit && (
            <CircularProgressWithLabel value={progressUpload} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ManagerDialog;
