import React from 'react';
import { Switch } from 'react-router-dom';

import Customers from '../pages/Customers';
import Dashboard from '../pages/Dashboard';
import Executable from '../pages/Executable';
import Home from '../pages/Home';
import Logs from '../pages/Logs';
import Manager from '../pages/Manager';
import NotFound from '../pages/NotFound';
import SignIn from '../pages/SignIn';
import Users from '../pages/Users';
import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} hideNavBar />
    <Route path="/dashboard" exact component={Dashboard} isPrivate />
    <Route path="/customers" exact component={Customers} isPrivate />
    <Route path="/logs" exact component={Logs} isPrivate />
    <Route
      path="/manager"
      exact
      component={Manager}
      isPrivate
      routeRole="admin"
    />
    <Route
      path="/executable"
      exact
      component={Executable}
      isPrivate
      routeRole="admin"
    />
    <Route path="/users" exact component={Users} isPrivate routeRole="admin" />
    <Route path="/signin" exact component={SignIn} hideNavBar />
    <Route path="*" component={NotFound} hideNavBar />
  </Switch>
);

export default Routes;
