import React, { useState, useEffect, useCallback } from 'react';

import { Snackbar, Container } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import MUIDataTable from 'mui-datatables';

import api from '../../services/api';
import useStyles from './styles';

interface Log {
  id: string;
  user: string;
  customer_cnpj: string;
  description: string;
  created_at: Date;
}

interface SnackBar {
  open: boolean;
  description: string;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Customer: React.FC = () => {
  const classes = useStyles();

  const [logs, setLogs] = useState<Log[]>([]);

  const [snackBarError, setSnackBarError] = useState<SnackBar>({
    open: false,
    description: '',
  });

  const [snackBarSuccess, setSnackBarSuccess] = useState<SnackBar>({
    open: false,
    description: '',
  });

  useEffect(() => {
    api.get('/logs/').then((response) => {
      setLogs(response.data);
    });
  }, []);

  const handleCloseSnackBarError = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarError({ open: false, description: '' });
    },
    [],
  );

  const handleCloseSnackBarSuccess = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackBarSuccess({ open: false, description: '' });
    },
    [],
  );

  return (
    <>
      <Snackbar
        open={snackBarError.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarError} severity="error">
          {snackBarError.description}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackBarSuccess.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackBarSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBarSuccess} severity="success">
          {snackBarSuccess.description}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            <MUIDataTable
              title="Logs - HM Gerencial"
              data={logs}
              columns={[
                { name: 'id', options: { display: 'excluded' } },
                {
                  name: 'user',
                  label: 'Usuário',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 150,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'customer_cnpj',
                  label: 'CNPJ',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: 115,
                            height: 58,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {value}
                          </span>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'description',
                  label: 'Descrição',
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div
                          style={{
                            width: 300,
                            height: 58,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {value}
                        </div>
                      );
                    },
                  },
                },
                {
                  name: 'created_at',
                  label: 'Criado em',
                  options: {
                    customBodyRender: (value) => {
                      const parsedDate = parseISO(value);

                      const znDate = zonedTimeToUtc(parsedDate, 'UTC');

                      const formattedDate = format(znDate, 'dd/MM/yyyy HH:mm');
                      return (
                        <div
                          style={{
                            display: 'flex',
                            width: 120,
                            height: 58,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {formattedDate}
                        </div>
                      );
                    },
                  },
                },
              ]}
              options={{
                pagination: false,
                responsive: 'standard',
                selectableRows: 'none',
                textLabels: {
                  body: {
                    noMatch: 'Nenhum registro encontrado',
                    toolTip: 'Classificar',
                  },
                  pagination: {
                    next: 'Próxima página',
                    previous: 'Página anterior',
                    rowsPerPage: 'Por página:',
                    displayRows: 'de',
                  },
                  toolbar: {
                    search: 'Busca',
                    downloadCsv: 'Download CSV',
                    print: 'Imprimir',
                    viewColumns: 'Ver Colunas',
                    filterTable: 'Filtrar Tabelas',
                  },
                  filter: {
                    all: 'Todos',
                    title: 'FILTROS',
                    reset: 'LIMPAR',
                  },
                  viewColumns: {
                    title: 'Ver Colunas',
                    titleAria: 'Ver/Esconder Colunas da Tabela',
                  },
                  selectedRows: {
                    text: 'registros(s) selecionados',
                    delete: 'Excluir',
                    deleteAria: 'Excluir registros selecionados',
                  },
                },
              }}
            />
          </Container>
        </main>
      </div>
    </>
  );
};

export default Customer;
