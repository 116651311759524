import React, { useState, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Grid,
  FormControlLabel,
  WithStyles,
  withStyles,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Formik } from 'formik';
import * as Yup from 'yup';

import api from '../../services/api';
import styles from './styles';

interface Customer {
  id: string;
  company_name: string;
  trade_name: string;
  cnpj: string;
  address: string;
  number: string;
  neighborhood: string;
  complement: string;
  cep: string;
  city: string;
  state: string;
  contact: string;
  phone: string;
  cell_phone: string;
  email: string;
  message: string;
  observation: string;
  contract_date: Date;
  blocked: boolean;
}

interface CustomerDialogProps {
  open: boolean;
  selectedCustomer: Customer;
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CustomerDialog: React.FC<CustomerDialogProps> = ({
  open,
  selectedCustomer,
  onClose,
  onError,
  onSuccess,
}) => {
  const [customer, setCustomer] = useState(selectedCustomer);

  useEffect(() => {
    setCustomer({
      ...selectedCustomer,
      complement: !selectedCustomer.complement
        ? 'Nenhum complemento'
        : selectedCustomer.complement,
    });
  }, [selectedCustomer]);

  const handleClose = () => {
    onClose();
  };

  const handleError = () => {
    onError();
  };

  const handleSuccess = () => {
    onSuccess();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Alterar dados do cliente
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={
            {
              id: customer.id,
              company_name: customer.company_name,
              trade_name: customer.trade_name,
              cnpj: customer.cnpj,
              address: customer.address,
              number: customer.number,
              neighborhood: customer.neighborhood,
              complement: customer.complement,
              cep: customer.cep,
              city: customer.city,
              state: customer.state,
              contact: customer.contact,
              phone: customer.phone,
              cell_phone: customer.cell_phone,
              email: customer.email,
              message: customer.message,
              observation: customer.observation,
              contract_date: customer.contract_date,
              blocked: customer.blocked,
            } as Customer
          }
          onSubmit={(values: Customer, { setSubmitting }) => {
            setSubmitting(true);

            api
              .put(`/customers/${customer.id}`, {
                contract_date: values.contract_date,
                blocked: values.blocked,
                message: !values.message ? null : values.message,
                observation: !values.observation ? null : values.observation,
              })
              .then(() => {
                handleSuccess();
              })
              .catch(() => {
                handleError();
              });

            handleClose();
          }}
          validationSchema={Yup.object().shape({
            id: Yup.string().uuid().required('Required'),
            contract_date: Yup.date().required('Required'),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  value={values.id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="id"
                  label="ID"
                  type="text"
                  hidden
                  disabled
                  fullWidth
                  helperText={errors.id && touched.id && errors.id}
                />
                <TextField
                  value={values.company_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="company_name"
                  label="Nome da empresa"
                  type="text"
                  disabled
                  fullWidth
                  helperText={
                    errors.company_name &&
                    touched.company_name &&
                    errors.company_name
                  }
                />
                <TextField
                  value={values.trade_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="trade_name"
                  label="Nome comercial"
                  type="text"
                  disabled
                  fullWidth
                  helperText={
                    errors.trade_name && touched.trade_name && errors.trade_name
                  }
                />
                <TextField
                  value={values.cnpj}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  id="cnpj"
                  name="cnpj"
                  label="CNPJ"
                  type="text"
                  disabled
                  fullWidth
                  helperText={errors.cnpj && touched.cnpj && errors.cnpj}
                />
                <TextField
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  id="address"
                  name="address"
                  label="Endereço"
                  type="text"
                  disabled
                  fullWidth
                  helperText={
                    errors.address && touched.address && errors.address
                  }
                />
                <TextField
                  value={values.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="number"
                  label="Numero"
                  type="text"
                  disabled
                  fullWidth
                  helperText={errors.number && touched.number && errors.number}
                />
                <TextField
                  value={values.neighborhood}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="neighborhood"
                  label="Bairro"
                  type="text"
                  disabled
                  fullWidth
                  helperText={
                    errors.neighborhood &&
                    touched.neighborhood &&
                    errors.neighborhood
                  }
                />
                <TextField
                  value={values.complement}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="complement"
                  label="Complemento"
                  type="text"
                  disabled
                  fullWidth
                  helperText={
                    errors.complement && touched.complement && errors.complement
                  }
                />
                <TextField
                  value={values.cep}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="cep"
                  label="CEP"
                  type="text"
                  disabled
                  fullWidth
                  helperText={errors.cep && touched.cep && errors.cep}
                />
                <TextField
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="city"
                  label="Cidade"
                  type="text"
                  disabled
                  fullWidth
                  helperText={errors.city && touched.city && errors.city}
                />
                <TextField
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="state"
                  label="Estado"
                  type="text"
                  disabled
                  fullWidth
                  helperText={errors.state && touched.state && errors.state}
                />
                <TextField
                  value={values.contact}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="contact"
                  label="Contato"
                  type="text"
                  disabled
                  fullWidth
                  helperText={
                    errors.contact && touched.contact && errors.contact
                  }
                />
                <TextField
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="phone"
                  label="Telefone"
                  type="text"
                  disabled
                  fullWidth
                  helperText={errors.phone && touched.phone && errors.phone}
                />
                <TextField
                  value={values.cell_phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="cell_phone"
                  label="Celular"
                  type="text"
                  disabled
                  fullWidth
                  helperText={
                    errors.cell_phone && touched.cell_phone && errors.cell_phone
                  }
                />
                <TextField
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="email"
                  label="E-mail"
                  type="text"
                  disabled
                  fullWidth
                  helperText={errors.email && touched.email && errors.email}
                />
                <TextField
                  autoFocus
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="message"
                  label="Mensagem"
                  type="text"
                  autoComplete="off"
                  multiline
                  placeholder="Insira sua mensagem"
                  fullWidth
                  helperText={
                    errors.message && touched.message && errors.message
                  }
                />
                <TextField
                  value={values.observation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="observation"
                  label="Observação"
                  type="text"
                  autoComplete="off"
                  multiline
                  placeholder="Insira sua observação"
                  fullWidth
                  helperText={
                    errors.observation &&
                    touched.observation &&
                    errors.observation
                  }
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Data do contrato"
                      value={values.contract_date}
                      onChange={(value) => {
                        props.setFieldValue('contract_date', value);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      fullWidth
                      helperText={
                        errors.contract_date &&
                        touched.contract_date &&
                        errors.contract_date
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.blocked}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        props.setFieldValue('blocked', event.target.checked);
                      }}
                      name="checkedBlocked"
                      color="primary"
                    />
                  }
                  label="Bloqueado"
                />
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    color="primary"
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" disabled={isSubmitting} color="primary">
                    Salvar
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerDialog;
