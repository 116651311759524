import React, { useState, useEffect } from 'react';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  FormControlLabel,
  WithStyles,
  withStyles,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import * as Yup from 'yup';

import api from '../../services/api';
import styles from './styles';

interface User {
  id: string;
  name: string;
  email: string;
  password: string;
  old_password?: string;
  role: string;
}

interface UserDialogProps {
  open: boolean;
  selectedUser: User;
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const UserDialog: React.FC<UserDialogProps> = ({
  open,
  selectedUser,
  onClose,
  onError,
  onSuccess,
}) => {
  const [user, setUser] = useState(selectedUser);
  const [isNewUser, setNewUser] = useState(false);

  useEffect(() => {
    setUser(selectedUser);

    setNewUser(
      selectedUser &&
        Object.keys(selectedUser).length === 0 &&
        selectedUser.constructor === Object,
    );
  }, [selectedUser]);

  const handleClose = () => {
    onClose();
  };

  const handleError = () => {
    onError();
  };

  const handleSuccess = () => {
    onSuccess();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {isNewUser ? 'Novo usuário' : 'Alterar dados do usuário'}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={
            {
              id: user.id,
              name: user.name,
              email: user.email,
              password: user.password,
              old_password: '',
              role: user.role ? user.role : 'employee',
            } as User
          }
          onSubmit={async (values: User, { setSubmitting }) => {
            setSubmitting(true);

            try {
              if (!values.id) {
                await api.post(`/users`, {
                  name: values.name,
                  email: values.email,
                  password: values.password,
                  role: values.role,
                });
              } else {
                await api.put(`/profile/${values.id}`, {
                  name: values.name,
                  email: values.email,
                  role: values.role,
                  ...(values.old_password && {
                    old_password: values.old_password,
                  }),
                  ...(values.password && {
                    password: values.password,
                    password_confirmation: values.password,
                  }),
                });
              }
              handleSuccess();
            } catch {
              handleError();
            } finally {
              handleClose();
            }
          }}
          validationSchema={Yup.object().shape({
            id: isNewUser
              ? Yup.string().nullable(true)
              : Yup.string().uuid().required('Campo obrigatório'),
            name: Yup.string().required('Campo obrigatório'),
            email: Yup.string().email().required('Campo obrigatório'),
            role: Yup.string().required('Campo obrigatório'),
            password: isNewUser
              ? Yup.string().required('Campo obrigatório')
              : Yup.string().nullable(true),
            old_password: Yup.string().when('password', {
              is: (password: string) =>
                password && !isNewUser && password.length > 0,
              then: Yup.string().required(
                'Campo obrigatório, para definir uma nova senha',
              ),
              otherwise: Yup.string().nullable(true),
            }),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                {!!values.id && (
                  <TextField
                    value={values.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="dense"
                    name="id"
                    label="ID"
                    type="text"
                    autoComplete="off"
                    hidden
                    disabled
                    fullWidth
                    helperText={errors.id && touched.id && errors.id}
                  />
                )}

                <TextField
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="name"
                  label="Nome completo"
                  type="text"
                  autoComplete="off"
                  fullWidth
                  helperText={errors.name && touched.name && errors.name}
                />
                <TextField
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="email"
                  label="E-mail"
                  type="text"
                  autoComplete="off"
                  fullWidth
                  helperText={errors.email && touched.email && errors.email}
                />

                <TextField
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  name="password"
                  label={values.id ? 'Nova senha' : 'Senha'}
                  type="password"
                  autoComplete="off"
                  fullWidth
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                />

                {!!values.id && (
                  <TextField
                    value={values.old_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="dense"
                    name="old_password"
                    label="Senha antiga"
                    type="password"
                    autoComplete="off"
                    fullWidth
                    helperText={
                      errors.old_password &&
                      touched.old_password &&
                      errors.old_password
                    }
                  />
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.role === 'admin'}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        props.setFieldValue(
                          'role',
                          event.target.checked ? 'admin' : 'employee',
                        );
                      }}
                      name="checkedRole"
                      color="primary"
                    />
                  }
                  label="Administrador"
                />
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    color="primary"
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" disabled={isSubmitting} color="primary">
                    Salvar
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UserDialog;
