import React, { createContext, useCallback, useState, useContext } from 'react';

import { decode } from 'jsonwebtoken';

import api from '../services/api';

interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  employee: boolean;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
  checkExpiredToken(): void;
}

interface TokenPayload {
  payload: {
    exp: number;
    role: string | null;
    employee: boolean;
  };
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@hm-gerencial:token');
    const user = localStorage.getItem('@hm-gerencial:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user }: { token: string; user: User } = response.data;

    const decoded = decode(token, {
      complete: true,
    });

    const {
      payload: { role, employee },
    } = decoded as TokenPayload;

    user.role = role || '';
    user.employee = employee;

    localStorage.setItem('@hm-gerencial:token', token);
    localStorage.setItem('@hm-gerencial:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@hm-gerencial:token');
    localStorage.removeItem('@hm-gerencial:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@hm-gerencial:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  const checkExpiredToken = useCallback((): void => {
    if (data.token) {
      const decoded = decode(data.token, {
        complete: true,
      });

      const {
        payload: { exp },
      } = decoded as TokenPayload;

      const dateInSeconds = Math.floor(new Date().getTime() / 1000);

      if (exp < dateInSeconds) {
        localStorage.removeItem('@hm-gerencial:token');
        localStorage.removeItem('@hm-gerencial:user');

        setData({} as AuthState);
      }
    }
  }, [data.token]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        checkExpiredToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
